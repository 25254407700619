import React from "react";
import Img from "gatsby-image";
import { Link } from "gatsby";
import styled from "styled-components";
import config from "../../../config";

const Services = ({ nodes }) => {

  return (

    <Container>
    	 <h2>Our Services</h2>

    	<ServicesContainer>

         {
            nodes.map(service => (
              <ServiceLink to={service.node.path.alias}>
                <Img fluid={service.node.relationships.field_banner_image.localFile.childImageSharp.fluid} alt={service.node.title} />
                <h3>{service.node.title}</h3>
                <p>{service.node.field_summary}</p>
              </ServiceLink> 
            ))
          }

    	</ServicesContainer>

	   
    </Container>  
  );
}

const ServiceLink = styled(Link) `
	padding: 0 30px 30px 30px;
	text-decoration: none;
	color: ${config.secondaryColor};
  flex-basis: 0;
  flex-grow: 1;

  h3 {
  	margin-top: 20px;
  	margin-bottom: 10px;
  	text-transform: uppercase;
  	font-size: 20px;
  }

  p {
  	font-size: 16px;
  }

  .gatsby-image-wrapper img {
  	transition: 600ms ease transform !important;
  }

  &:hover img {
  	transform: scale(1.1);
  }
` 

const Container = styled.div`
  padding: 30px 60px;
`

const ServicesContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 -30px;

  @media(min-width: 768px) {
  	 flex-direction: row;
  }
`

export default Services